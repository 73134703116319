<template>
  <svg width="55px" height="58px" viewBox="0 0 55 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 56 (101010) - https://sketch.com -->
    <title>BF2E132B-4359-4588-8A4B-CC55331ACB21</title>
    <desc>Created with sketchtool.</desc>
    <g id="Asset-Artboard-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="icons/documents/bank-extract" transform="translate(0.918028, 0.000000)">
            <g id="icon" transform="translate(0.000000, 1.000000)">
                <rect id="bg" stroke="#979797" fill="#D8D8D8" opacity="0" x="0.5" y="0.5" width="53" height="54.8028846"></rect>
                <g id="Group-2" transform="translate(0.000000, 8.000000)">
                    <rect id="bg-copy" :fill="color" transform="translate(27.000000, 19.901442) rotate(90.000000) translate(-27.000000, -19.901442) " x="8" y="-7.09855769" width="38" height="54" rx="2"></rect>
                    <path d="M13.5,11 C14.7857207,11.0352943 15.8571429,11.4764664 16.7142857,12.3235294 C17.5714286,13.1705925 18,14.2382289 18,15.5264706 C18,16.8147123 17.5714286,17.8823487 16.7142857,18.7294118 C15.8571429,19.5764748 14.7857207,20 13.5,20 C12.2142793,20 11.1428571,19.5764748 10.2857143,18.7294118 C9.42857143,17.8823487 9,16.8147123 9,15.5264706 C9,14.2382289 9.42857143,13.1705925 10.2857143,12.3235294 C11.1428571,11.4764664 12.2142793,11.0352943 13.5,11 Z M13.5,22 C16.2037172,22.0364585 18.4444356,22.4921831 20.2222222,23.3671875 C22,24.2421919 22.9259256,25.3359309 23,26.6484375 L23,29 L4,29 L4,26.6484375 C4.07407444,25.3359309 5,24.2421919 6.77777778,23.3671875 C8.55556444,22.4921831 10.7962828,22.0364585 13.5,22 Z" id="" fill="#FFFFFF" fill-rule="nonzero"></path>
                    <rect id="Rectangle-Copy-6" fill="#FFFFFF" x="26" y="11" width="24" height="5"></rect>
                    <rect id="Rectangle-Copy-7" fill="#FFFFFF" x="26" y="20" width="11" height="3"></rect>
                    <rect id="Rectangle-Copy-9" fill="#FFFFFF" x="39" y="20" width="11" height="3"></rect>
                    <rect id="Rectangle-Copy-8" fill="#FFFFFF" x="26" y="26" width="24" height="3"></rect>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
    props:{
        color: {
            type: String,
        }
    }
}
</script>

<style>

</style>